import { Environments } from '@/enums/environments';
import dgApiUrls from '@/environment/dgApiUrls';
import { getDgidCookieName } from '@/utilities/getCookieNames';
import { checkIdTokenCookie } from '@/utilities/getIdToken';
import Cookies from 'js-cookie';
import { AuthLogger } from './logger';
import { retryUntilTruthyOrTimeout } from '@/utilities/promise';
import { getTier } from '@/utilities/getTier';

export async function handleLogin(env: Environments): Promise<unknown> {
    const dgApi = await getDgApi(env);
    return dgApi.handleLogin();
}

export async function handleLogout(env: Environments): Promise<unknown> {
    const dgApi = await getDgApi(env);
    return dgApi.handleLogout();
}

const dgApiPromises: Partial<Record<Environments, Promise<DgApi>>> = {};
async function getDgApi(env: Environments): Promise<DgApi> {
    const existingDgApiPromise = dgApiPromises[env];
    if (existingDgApiPromise) {
        return existingDgApiPromise;
    }
    const newDgApiPromise = _getDgApi(env);
    dgApiPromises[env] = newDgApiPromise;
    return newDgApiPromise;
}
async function _getDgApi(env: Environments): Promise<DgApi> {
    return new DgApi(env);
}

class DgApi {
    private readonly env: Environments;

    constructor(env: Environments) {
        this.env = env;
    }

    handleLogin() {
        const url = `${this.getBaseUrl()}/garage/v2/auth/events/login`;
        const body = this.buildBody();
        if (!body.dgid) {
            AuthLogger.info('No dgid, skipping login event POST');
            return null;
        }
        if (!body.id_token) {
            AuthLogger.info('No id_token, skipping login event POST');
            return null;
        }
        return postJson(url, body);
    }

    handleLogout() {
        const url = `${this.getBaseUrl()}/garage/v2/auth/events/logout`;
        const body = this.buildBody();
        if (!body.dgid) {
            AuthLogger.info('No dgid, skipping logout event POST');
            return null;
        }
        return postJson(url, body);
    }

    private getBaseUrl() {
        const baseUrl = dgApiUrls[getTier()][this.env];
        if (!baseUrl) {
            throw new Error(`No dgApi url found for env: '${this.env}'`);
        }
        return baseUrl;
    }

    private buildBody() {
        const dgid = this.getDgid();
        const { value: idToken, reason: idTokenReason } = this.getIdToken();

        return {
            dgid,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            id_token: idToken,
            meta: {
                reason: idTokenReason,
                source: window.location.href ?? 'unknown',
            },
        };
    }

    private getDgid() {
        const cookieName = getDgidCookieName(this.env as Environments);
        return Cookies.get(cookieName);
    }

    private getIdToken() {
        const isProd = this.env === 'prod';
        const idTokenName = isProd ? 'id_token' : 'id_token_tmnab2cqa';
        return this.getIdTokenValueWithReason(idTokenName);
    }

    // TODO: Revert this change to use the getIdToken function in utils after bug is fixed
    private getIdTokenValueWithReason(idTokenName: string): { value: string | null; reason: string } {
        const idTokenCookieResult = checkIdTokenCookie(idTokenName);
        if (idTokenCookieResult.isValidJWT) {
            return { value: idTokenCookieResult.jwt, reason: 'valid_jwt' };
        } else if (idTokenCookieResult.pkceSaysTheyAreLoggedIn) {
            AuthLogger.debug(`Getting id_token information from local storage`);
            const localIdToken = localStorage.getItem(idTokenName);
            return localIdToken
                ? { value: localIdToken, reason: 'pkce_use_local_token' }
                : { value: null, reason: 'pkce_no_local_token' };
        }
        return { value: null, reason: 'catch_all_invalid_token' };
    }
}

async function postJson(url: string, json: Record<string, unknown>) {
    const challengeScriptLoaded = await retryUntilTruthyOrTimeout(() => window.AwsWafIntegration, {
        delayMs: 100,
        timeoutMs: 5000,
    });
    if (!challengeScriptLoaded) {
        AuthLogger.warn('Challenge script not loaded. Skipping event', json);
        return null;
    }
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(json),
        headers: {
            'Content-Type': 'application/json',
            'X-Aws-Waf-Token': (await window.AwsWafIntegration?.getToken()) ?? 'No Challenge Loaded',
        },
    });
}
