import { Environments } from '@/enums/environments';
import type { PageDGDataHub } from '@/models/PageDgDataHub.model';
import { wasAutoLoggedOutCookie, showAutoLoggedOutNotificationCookie } from '@/utilities/autoLogoutCookies';
import PersistedArray from '@/utilities/persistedArray';
import type AuthState from './authState';
import { AuthEvent, EventType, isSameAuthEvent } from './events';
import { AuthLogger } from './logger';
import { handleLogin, handleLogout } from './service';

export default class EventHandler {
    private readonly authState;
    private readonly inProgressEvents;
    private readonly finishedEvents;

    constructor(authState: AuthState) {
        this.authState = authState;
        this.inProgressEvents = new PersistedArray<AuthEvent>('dg-auth-events-in-progress', 5, isSameAuthEvent);
        this.finishedEvents = new PersistedArray<AuthEvent>('dg-auth-events-finished', 5, isSameAuthEvent);
    }

    /**
     * Some events may have been started to be handled on a previous page load, but then a refresh or navigate stopped the handling from finishing.
     * In that case, those events will still in present in the "in progress" state, and we want to restart them to ensure that they finish.
     * Should be called on page load.
     */
    async finishPendingEvents(): Promise<void> {
        const inProgressEvents = this.inProgressEvents.items;
        if (inProgressEvents.length > 0) {
            AuthLogger.debug('Finishing pending events', inProgressEvents);
            for (const inProgressEvent of inProgressEvents) {
                await this.handleEvent(inProgressEvent);
            }
        }
    }

    /**
     * Queue events to be handled on the next page load
     */
    queueEvents(events: AuthEvent[]): void {
        if (events.length > 0) {
            AuthLogger.debug('Queueing events', events);
            for (const event of events) {
                this.setEventHandlingState('started', event);
            }
        }
    }

    async handleEvent(event: AuthEvent): Promise<void> {
        if (this.eventHasAlreadyBeenHandled(event)) {
            AuthLogger.debug('Skipping event, because it was already handled', event);
            this.setEventHandlingState('finished', event);
            return;
        }

        AuthLogger.info('Handling event', event);
        this.setEventHandlingState('started', event);
        try {
            await this._handleEvent(event);
            AuthLogger.debug('Successfully handled event', event);
        } catch (error) {
            AuthLogger.error('Error handling event', event, error);
        } finally {
            this.setEventHandlingState('finished', event);
        }
    }

    private _setAutoLogoutEventCookies() {
        wasAutoLoggedOutCookie.set(true);
        showAutoLoggedOutNotificationCookie.set(true);
    }

    private _removeAutoLogoutEventCookies() {
        wasAutoLoggedOutCookie.set(false);
        showAutoLoggedOutNotificationCookie.set(false);
    }

    private async _handleEvent(event: AuthEvent) {
        let dgDataHubEnv = (window.DGDataHub as PageDGDataHub | undefined)?.DEPLOY_ENV?.toLowerCase() as Environments;
        if (!dgDataHubEnv) {
            const overrideEnvKey = '__dg_override_env__';
            const overrideEnv =
                window.localStorage.getItem(overrideEnvKey) || window.sessionStorage.getItem(overrideEnvKey);
            dgDataHubEnv = overrideEnv?.toLowerCase() as Environments;
        }
        const env = dgDataHubEnv || event.data.env;
        AuthLogger.setEnv(env);
        switch (event.type) {
            case EventType.tokenRequested:
                this.authState.isAuthInProgress = true;
                break;
            case EventType.tokenCreated:
                await this.processAuth(() => handleLogin(env));
                this._removeAutoLogoutEventCookies();
                break;
            case EventType.tokenDeleted:
                await this.processAuth(() => handleLogout(env));
                if (event.data.logout_reason === 'SESSION_TIMEOUT') {
                    this._setAutoLogoutEventCookies();
                }
                break;
            default:
                AuthLogger.warn(`Invalid event type: ${(event as AuthEvent).type}`);
                break;
        }
    }

    private async processAuth(processor: () => Promise<unknown>) {
        this.authState.isAuthInProgress = true;
        try {
            await processor();
        } finally {
            this.authState.isAuthInProgress = false;
        }
    }

    eventHasAlreadyBeenHandled(event: AuthEvent): boolean {
        return this.finishedEvents.items.some((finishedEvent) => isSameAuthEvent(finishedEvent, event));
    }

    private setEventHandlingState(state: 'started' | 'finished', event: AuthEvent) {
        if (state === 'started') {
            this.inProgressEvents.addItem(event);
        } else if (state === 'finished') {
            this.inProgressEvents.removeItem(event);
            this.finishedEvents.addItem(event);
        }
    }
}
