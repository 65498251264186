import type { PageDGDataHub } from '@/models/DGDataHub.model';
import { DgObservable } from '@/utilities/observable';
import { AuthLogger } from './logger';

export default class AuthState {
    private readonly isAuthInProgressObservable: DgObservable<boolean>;

    constructor() {
        this.isAuthInProgressObservable = new DgObservable<boolean>(false);
        AuthState.setObservableOnDataHub(this.isAuthInProgressObservable);
    }

    private static setObservableOnDataHub(isAuthInProgressObservable: DgObservable<boolean>) {
        const pageDataHub = window.DGDataHub as PageDGDataHub;
        if (!pageDataHub) {
            setTimeout(() => AuthState.setObservableOnDataHub(isAuthInProgressObservable), 50);
            return;
        }
        pageDataHub.__internal__ ??= {};
        pageDataHub.__internal__.isAuthInProgress = isAuthInProgressObservable;
    }

    set isAuthInProgress(inProgess: boolean) {
        AuthLogger.debug('Setting isAuthInProgress', inProgess);
        this.isAuthInProgressObservable.setValue(inProgess);
    }
    get isAuthInProgress(): boolean {
        return this.isAuthInProgressObservable.getValue();
    }
}
